import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import propTypes from 'prop-types';
import cn from 'classnames';

import { currency } from '../../../utils/numbers';
import { RubleSign } from '../RubleSign';
import { Labels } from '../Labels';

import styles from './ProductCard.module.scss';

function ProductPrice({ product }) {
  if (product.isUnavailable) {
    return (
      <div
        className={cn({
          [styles.price]: true,
          [styles.unavailablePrice]: product.isUnavailable,
        })}
      >
        Нет в наличии
      </div>
    );
  }

  // Из-за того что по раному собираются данные => появились options.pageOptions и options.
  // Нужно будет потом поправить

  const salePrice =
    product.options.pageOptions?.[0].optionSalePrice || product.options?.[0]?.optionSalePrice;

  const price = product.options.pageOptions?.[0].price
    ? product.options.pageOptions[0].price
    : product.options[0].price;

  if (salePrice) {
    return (
      <div
        className={cn({
          [styles.price]: true,
        })}
      >
        {currency(salePrice)}
        &nbsp;
        <RubleSign />
        <span className={styles.realPrice}>
          {currency(price)}
          &nbsp;
          <RubleSign />
        </span>
      </div>
    );
  }

  return (
    <div
      className={cn({
        [styles.price]: true,
      })}
    >
      {currency(price)}
      &nbsp;
      <RubleSign />
    </div>
  );
}

export function ProductCard({ product, className, disableLabels }) {
  return (
    <Link
      to={product.url}
      className={cn({
        [styles.card]: true,
        [className]: !!className,
      })}
    >
      <GatsbyImage
        alt={product.title}
        className={styles.image}
        image={getImage(product.image?.localFile)}
      />

      {!disableLabels && (
        <Labels labels={product.labels} customClassName={styles.labelCustomContainer} />
      )}

      <div className={styles.metadata}>
        <ProductPrice product={product} />
        <h4 className={styles.title}>{product.title}</h4>
        <p className={styles.subtitle}>{product.subtitle}</p>
      </div>
    </Link>
  );
}

ProductCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: propTypes.object.isRequired,
  className: propTypes.string,
  disableLabels: propTypes.bool,
};

ProductCard.defaultProps = {
  className: '',
  disableLabels: false,
};
